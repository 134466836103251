<template>
  <div
      class="page page-test"
      :class="[
      'distance-' + (this.$store.state.distance === 0 ? '40cm' : '2m'),
      'round-' + (1 + this.testData.progress.index),
    ]"
  >
    <div class="test-container">
      <div class="swipe-region"
           v-touch:swipe="swipeHandler"
      >
      </div>
      <div class="center-wrapper">
        <div class="top_row">
          <button
              class="round-circle key-circle up inverted-btn"
              :class="(this.lastKeyEvent === 'ArrowUp' || this.lastSwipeEvent === 'top') ? 'animate-key-pressed' : ''"
              v-show="!isSmallDevice"
              :disabled="
              this.testData.progress.canGoNextRound || this.testData.progress.canShowResults
            "
              v-on:click="this.$store.commit('testGoNext', directions.UP)"
          >
            {{ $t('page-test.up') }}
          </button>
        </div>

        <div class="middle_row" :class="'distance-' + (this.$store.state.distance === 0 ? '40-cm' : '2-m')">
          <div class="left-button">
            <button
                class="round-circle key-circle left inverted-btn"
                v-show="!isSmallDevice"
                :class="(this.lastKeyEvent === 'ArrowLeft' || this.lastSwipeEvent === 'left') ? 'animate-key-pressed' : ''"
                :disabled="
                this.testData.progress.canGoNextRound || this.testData.progress.canShowResults
              "
                v-on:click="this.$store.commit('testGoNext', directions.LEFT)"
            >
              {{ $t('page-test.left') }}
            </button>
          </div>
          <div class="test-border-box-wrapper">
            <div
                class="test-border-box"
                :class="(this.testData.progress.canGoNextRound || this.testData.progress.canShowResults) ? 'disabled' : ''"
                :style="{
                'border-width': `${this.state.round.sizes.border.widthAroundAllSymbols}px`,
                width: this.state.round.sizes.widthAllSymbolsAndBorder + 'px',
                height: this.state.round.sizes.heightAllSymbolsAndBorder + 'px',
              }"
            >
              <div
                  class="ring-wrapper"
                  :style="{
                  padding: this.state.round.sizes.paddingAroundSymbol + 'px 0',
                }"
              >
                <template v-for="(ring, idx) in this.state.round.rings" :key="idx">
                  <div
                      class="ring"
                      :class="['ring-' + (1 + idx), { active: this.testData.progress.ring === idx }]"
                      :style="{
                      width: this.state.round.sizes.symbolSize + 'px',
                    }"
                  >
                    <img v-if="this.testData.progress.canGoNextRound || this.testData.progress.canShowResults"
                         src="../../assets/ring-disabled.svg"
                         :style="{ transform: 'rotate(' + ring * 90 + 'deg)' }"
                         alt="Ring disabled"
                    />
                    <img v-else
                         src="../../assets/ring.svg"
                         :style="{ transform: 'rotate(' + ring * 90 + 'deg)' }"
                         alt="Ring"
                    />
                    <div
                        class="progress"
                        v-show="this.testData.progress.ring === idx"
                        :style="{
                        bottom: '-' + this.state.round.sizes.progressBulletSpacingFromSymbol + 'px',
                        width: this.state.round.sizes.progressBulletSize + 'px',
                        height: this.state.round.sizes.progressBulletSize + 'px',
                      }"
                    ></div>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="right-button">
            <button
                class="round-circle key-circle right inverted-btn"
                :class="(this.lastKeyEvent === 'ArrowRight' || this.lastSwipeEvent === 'right') ? 'animate-key-pressed' : ''"
                v-show="!isSmallDevice"
                :disabled="
                this.testData.progress.canGoNextRound || this.testData.progress.canShowResults
              "
                v-on:click="this.$store.commit('testGoNext', directions.RIGHT)"
            >
              {{ $t('page-test.right') }}
            </button>
          </div>
        </div>

        <div class="bottom_row">
          <button
              class="round-circle key-circle down inverted-btn"
              :class="(this.lastKeyEvent === 'ArrowDown' || this.lastSwipeEvent === 'bottom') ? 'animate-key-pressed' : ''"
              v-show="!isSmallDevice"
              :disabled="
              this.testData.progress.canGoNextRound || this.testData.progress.canShowResults
            "
              v-on:click="this.$store.commit('testGoNext', directions.DOWN)"
          >
            {{ $t('page-test.down') }}
          </button>
        </div>
      </div>

    </div>

    <div class="status-actions">
      <div class="status">
        <div v-for="(eye) in [firstEye, secondEye]" class="block-50" :key="eye"
             :class="['testing', eye + '-test', this.testData.progress.eye === eye ? 'active' : '']">
          <div
              class="message"
              :class="(this.testData.progress.eye === eye && (this.testData.progress.canShowResults === undefined || this.testData.progress.canShowResults === false)) ? 'active' : ''"
              v-html="
                '<p>' +
                  $t('global.' + eye + '-eye-cover-other-eye') +
                  '</p>'
              "
          ></div>

          <div class="steps" :class="this.testData.progress.eye === eye ? 'active' : ''">
            <div
                v-for="n in this.roundsPerEye"
                :key="n"
                :class="this.getStepClasses(eye, n)"
            ></div>
          </div>
        </div>


        <div class="quit-test-button block-40">
          <div class="">
            <button
                class="btn small-btn body-small text-center margin-right-xs"
                v-if="this.testData.progress.canGoPrev"
                v-on:click="this.$store.commit('testGoPrev')"
            >
              {{ $t('page-test.undo-last') }}
            </button>
          </div>
          <div class="next-button">
            <button
                class="btn primary-bg small-btn body-small text-center "
                :disabled="
                !this.testData.progress.canGoNextRound && !this.testData.progress.canShowResults
              "
                v-on:click="goNext"
            >
              {{
                this.testData.progress.canShowResults
                    ? $t('page-test.show-results')
                    : $t('page-test.next-round')
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {capitalize} from '@vue/shared';
import {firstEye, roundStatus, secondEye} from '@/constants';
import LeftOrRightEye from "@/components/LeftOrRightEye";

const {noticeTypes, directions} = require('@/constants');

export default {
  components: [LeftOrRightEye],
  data() {
    const width = window.innerWidth;
    return {
      firstEye,
      secondEye,
      width,
      noticeTypes: noticeTypes,
      directions: directions,
      capitalize: capitalize,
      testData: {},
      state: {
        round: {},
        step: 1,
      },
      lastSwipeEvent: 'none',
      lastKeyEvent: null,
      canPressKeyOrSwipe: true,
      listOfKeyOverrideCanPress: ['Enter', 'Space', 'Backspace', 'Delete', 'Escape'],
    };
  },
  computed: {
    isSmallDevice() {
      /**
       * From the total width, we want to subtract the width of the box, of the buttons and also the padding between
       * the box and buttons. With the results, we check if we have a minimum size (outside borders), if not, then
       * it is considered a small device.
       */

      const boxWidth = this.state.round.sizes.widthAllSymbolsAndBorder;

      // check variables.scss file for this
      const buttonPadding = 24 * 2.5;
      const buttonWidth = 24 * 4;

      let delta = this.width - boxWidth - (2 * buttonPadding + 2 * buttonWidth);

      return delta / 2 < 10;
    },
    roundsPerEye() {
      return this.$store.state.roundsPerEye;
    },
  },
  methods: {
    refreshListener(e){
      e.preventDefault();
    },
    getStepClasses(eye, step) {
      const index = step - 1;
      let classes = ['step', 'round-circle', 'big-circle', 'borders', 'step-' + step];
      if (this.testData.progress.eye === eye) {
        if (this.testData.progress.canShowResults) {
          classes.push('not-done');
          classes.push('not-active');
        } else {
          classes.push('active');
        }
      }

      if (this.testData.progress.eye === eye && this.testData.progress.index === index) {
        if (!this.testData.progress.canShowResults) {
          classes.push('current');
        }
      } else {
        if (this.testData.progress.eye !== eye) {
          classes.push('not-active');
          if (this.testData.rounds[eye][index].status === undefined && this.testData.progress.eye === secondEye) {
            classes.push('not-done');
          }
        }
      }

      classes.push({
        success: this.testData.rounds[eye][index].status === roundStatus.SUCCESS,
        failed: this.testData.rounds[eye][index].status === roundStatus.FAILED,
      });

      return classes;
    },
    goNext() {
      if (this.testData.progress.eye === secondEye) {
        this.$analyticsEyes(this.$store, "left");
      } else {
        this.$analyticsEyes(this.$store, "right");
      }
      if (this.testData.progress.canShowResults) {
        this.$store.commit('stopTimer');
        try {
          if (document.fullscreenEnabled && document.exitFullscreen) {
            document.exitFullscreen();
          }
        } catch (e) {
          console.error(e);
        }
        this.$store.commit('changePage', 'PageResults');
      } else {
        this.$store.commit('testGoNextRound');
      }
    },
    swipeHandler(direction) {
      if (!this.canPressKeyOrSwipe) return;
      // if (!this.isSmallDevice) return;
      this.lastSwipeEvent = direction;

      let td = null;
      switch (direction) {
        case 'top':
          td = directions.UP;
          break;
        case 'bottom':
          td = directions.DOWN;
          break;
        case 'left':
          td = directions.LEFT;
          break;
        case 'right':
          td = directions.RIGHT;
          break;
      }
      if (td === null) return;

      setTimeout(() => {
        this.lastSwipeEvent = null;
        this.$store.commit('testGoNext', td);
      }, 30);
    },
    arrowKeyHandler(e) {
      if (!this.canPressKeyOrSwipe && !this.listOfKeyOverrideCanPress.includes(e.code)) return;

      let td = null;
      this.lastKeyEvent = e.code;
      switch (e.code) {
        case 'ArrowUp':
          td = directions.UP;
          break;
        case 'ArrowDown':
          td = directions.DOWN;
          break;
        case 'ArrowLeft':
          td = directions.LEFT;
          break;
        case 'ArrowRight':
          td = directions.RIGHT;
          break;
        case 'Enter':
        case 'Space':
          if (this.testData.progress.canGoNextRound || this.testData.progress.canShowResults) {
            this.goNext();
          }
          break;
        case 'Backspace':
        case 'Delete':
        case 'Escape':
          this.$store.commit('testGoPrev');
          break;
      }

      if (td === null) return;

      setTimeout(() => {
        this.lastKeyEvent = null;
        this.$store.commit('testGoNext', td);
      }, 30);
    },
    arrowKeyHandleAnimation(e) {
      if (!this.canPressKeyOrSwipe) return;
      this.lastKeyEvent = e.code;
    },

    onResize() {
      this.width = window.innerWidth;
    },
    finishTest() {
      this.$store.commit('stopTimer');
      this.$store.commit('changePage', 'PageResults');
    },
  },
  watch: {
    '$store.state.testData': {
      deep: true,
      handler(newVal) {
        this.testData = newVal;
        this.state.round = newVal.rounds[newVal.progress.eye][newVal.progress.index];
        this.state.step =
            (newVal.progress.eye === secondEye ? this.roundsPerEye : 0) +
            newVal.progress.index +
            (newVal.progress.canShowResults ? 1 : 0) +
            1;
        document.title =
            this.$t('global.app-title') +
            ' | ' +
            this.$t('page-test.title', {
              round: this.state.step,
              rounds: this.roundsPerEye * 2,
            });
      },
    },
    '$store.state.testData.progress.eye': {
      deep: false,
      handler(newEye, oldEye) {
        if ((oldEye == null || newEye !== oldEye) && this.testData.progress.showNotice !== noticeTypes.TOOMANYERRORS && this.$store.state.currentPage === 'PageTest') {
          switch (newEye) {
            case firstEye:
              //this.canPressKeyOrSwipe = false;
              this.$store.commit('showModal',
                  {
                    componentToShowInsideModal: 'LeftOrRightEye',
                    argumentsToPassToAboveComponent: {'eyeToTest': firstEye},
                    modalButtonActionText1: this.$t('page-distance.info.start-test-button'),
                    onModalButton1Click: () => {
                      this.$store.commit('hideModal');
                      this.canPressKeyOrSwipe = true;
                    },
                  }
              );
              break;
            case secondEye:
              this.$store.commit('showModal',
                  {
                    componentToShowInsideModal: 'LeftOrRightEye',
                    argumentsToPassToAboveComponent: {'eyeToTest': secondEye},
                    modalButtonActionText1: this.$t('page-distance.info.start-test-button'),
                    onModalButton1Click: () => {
                      this.$store.commit('hideModal');
                      this.canPressKeyOrSwipe = true;
                    },
                  }
              );
              break;
          }
        }
      }
    },
    '$store.state.testData.progress.showNotice': {
      deep: false,
      handler(newNotice, oldNotice) {
        console.log('newNotice', newNotice);
        if (newNotice !== oldNotice) {
          if (newNotice === noticeTypes.TOOMANYERRORS && (this.testData.progress.canShowResults === undefined || this.testData.progress.canShowResults === false)) {
            this.canPressKeyOrSwipe = false;
            this.$store.commit('showModal',
                {
                  componentToShowInsideModal: 'TooManyErrors',
                  argumentsToPassToAboveComponent: {'eye': firstEye},
                  modalButtonActionText1: this.$t('page-distance.info.start-test-button'),
                  onModalButton1Click: () => {
                    this.$store.commit('dismissNotice');
                    this.$store.commit('hideModal');
                    this.canPressKeyOrSwipe = true;
                  },
                }
            );
          }
        }
      }
    },
    '$store.state.testData.progress': {
      deep: true,
      handler(newProgress) {
        this.canPressKeyOrSwipe = !newProgress.canGoNextRound && !newProgress.canShowResults
      }
    },
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    window.addEventListener('keyup', this.arrowKeyHandler);
    window.addEventListener('keydown', this.arrowKeyHandleAnimation);
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    window.addEventListener('beforeunload', this.refreshListener);
    document.documentElement.style.overflow = 'hidden';
    document.documentElement.style.overscrollBehavior = 'none';
  },
  unmounted() {
    window.removeEventListener('keyup', this.arrowKeyHandler);
    window.removeEventListener('keydown', this.arrowKeyHandleAnimation);
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('beforeunload', this.refreshListener);
    document.documentElement.style.overflow = '';
    document.documentElement.style.overscrollBehavior = '';
  },
  created() {
    this.$store.commit('initTest');
  },
};
</script>
